
.article-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    padding: 0 0 40px;

    .article-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 0 20px;
            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F1F5FF;
        height: 40px;
        padding-bottom: 10px;

        .genera-breadcrumb {
            display: flex;
            align-items: center;

            &:before {
                content: unset;
            }
        }
    }

    .content-title {
        position: relative;
        font-size: 20px;
        color: #333333;
        margin: 20px 0 0 16px;

        &:before {
            position: absolute;
            content: '';
            width: 5px;
            height: 100%;
            background: #2DC079;
            left: -16px;
            border-radius: 3px;
        }
    }

    .main-content {
        margin-top: 20px;
        display: flex;

        .content-left {
            width: 375px;
            height: 667px;

            .content-wrap {
                height: 100%;
                width: 100%;
                position: relative;

                .top-box {
                    padding: 10px 5px;
                    display: flex;
                    justify-content: space-between;

                    i {
                        color: #ffffff;
                    }

                    .top-text {
                        margin: 0 5px;
                        color: #ffffff;
                    }

                    .middle-box {
                        color: #ffffff;
                    }

                    .right-box {
                        color: #ffffff;
                    }
                }

                .middle-box {
                    display: flex;

                    i {
                        font-size: 24px;
                        color: #ffffff;
                    }

                    .middle-content {
                        border-radius: 25px;
                        opacity: 0.3;
                        background: #251D16;
                        display: flex;
                        align-items: center;

                        .content-wrap {
                            margin: 5px;
                            display: flex;
                            align-items: center;

                            .middle-text {
                                color: #ffffff;
                                margin-left: 5px;
                            }
                        }

                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                        }
                    }
                }

                .bottom-box {
                    position: absolute;
                    bottom: 60px;
                    right: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: #ffffff;

                        &:nth-of-type(2) {
                            font-size: 24px;
                        }
                    }

                    .orange-circle {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background: #F96D01;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
                    }

                    i + i {
                        margin-top: 20px;
                    }
                }
            }

            .left-title {
                margin-top: 10px;
                color: #999999;
            }
        }

        .content-right {
            display: flex;
            flex-direction: column;
            margin-left: 75px;

            .s500-input {
                width: 500px;
            }
        }
    }

    .page-button {
        margin-top: 100px;
        text-align: center;
    }

    .cover-uploader {
        width: 500px;

        :deep(.el-upload) {
            display: flex;
            align-items: center;

            .inner-box {
                width: 192px;
                height: 108px;
                background: #F7F7F7;
                border-radius: 4px;
                .inner-content {
                    padding: 29px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 24px;
                        color: #BFBFBF;
                    }

                    .inner-text {
                        font-size: 14px;
                        color: #666666;
                    }
                }
            }

            .cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 192px;
                height: 108px;
                margin-left: 15px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .cover-bottom {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    background: #000000;
                    opacity: 0.5;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 16px;
                    line-height: 36px;
                }
            }
        }
    }

    .item-txt-tip {
        color: #999999;
    }

    .weixin-content {
        display: flex;
        flex-direction: column;

        span {
            margin-top: 9px;
            color: #999999;
        }
    }
}
